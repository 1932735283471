import React from "react";

import ContactSectionOne from "./sections/ContactSectionOne";
import ContactSectionThree from "./sections/ContactSectionsThree";
import ContactSectionTwo from "./sections/ContactSectionTwo";

const Contact = () => {
  return (
    <div>
      <ContactSectionOne />
      <ContactSectionTwo />
      <ContactSectionThree />
    </div>
  );
};

export default Contact;
