import styled from "@emotion/styled";
import { pink as color, yellow } from "@mui/material/colors";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import Section from "../../../components/Section";

const Container = styled.div`
  text-align: center;
  color: white;
`;

const ResponsiveMapContainer = styled.div`
  position: relative;
  height: 300px;
  width: 100%;

  @media (min-width: 600px) {
    width: 600px;
  }
  border-radius: 35px;
  overflow: hidden;
`;

const ContactSectionThree = () => {
  return (
    <div>
      <Section backgroundColor={color["A400"]} padding="3">
        <Container>
          <Typography variant="h4" component="h1">
            Let's start a Project
          </Typography>
          <Typography variant="h5" component="h2">
            drop us a message at
          </Typography>
        </Container>
        <br />
        <Container>
          <Link
            sx={{ color: yellow["A200"] }}
            underline="always"
            href="mailto:info@greyformstudio.com?Subject=Let's%20work%20together%20fyi_Architects"
          >
            <Typography variant="h6" component="span">
              info@greyformstudio.com
            </Typography>
          </Link>
        </Container>
      </Section>
    </div>
  );
};

const SecondMap = () => (
  <Grid xs={12} sm={6}>
    <ResponsiveMapContainer>
      <iframe
        title="Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8841.037486688449!2d76.8598621777085!3d30.67595578739874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f948427f466ff%3A0xbc0707ab571adea0!2s881%2C+Sector+21%2C+Budanpur%2C+Panchkula%2C+Haryana+134112!5e0!3m2!1sen!2sin!4v1563693471573!5m2!1sen!2sin"
        width="600"
        height="450"
        frameBorder="0"
        allowFullScreen
        style={{ width: "100%", height: "inherit", border: "0" }}
      />
    </ResponsiveMapContainer>
  </Grid>
);

export default ContactSectionThree;
