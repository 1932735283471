import styled from "@emotion/styled";
import PhoneIcon from "@mui/icons-material/Phone";
import { orange as color } from "@mui/material/colors";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import Section from "../../../components/Section";

const Container = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
  color: black;
`;

const ResponsiveMapContainer = styled.div`
  position: relative;
  height: 300px;
  width: 100%;

  @media (min-width: 600px) {
    width: 600px;
  }
  border-radius: 35px;
  overflow: hidden;
`;

const PhoneContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContactSectionTwo = () => {
  return (
    <div>
      <Section backgroundColor={color["500"]} padding="3">
        <Container>
          <Typography variant="h4" component="h1">
            Visit Us
          </Typography>
          <Typography variant="h5" component="h2">
            We look forward to meeting you.
          </Typography>
        </Container>
        <Container>
          <Typography variant="h6" component="p">
            Patiala
          </Typography>
        </Container>
        <Grid container spacing={2} justifyContent="center">
          <ResponsiveMapContainer>
            <iframe
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13769.289569475575!2d76.403367!3d30.370202!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf82a5090cc22d463!2sFyi+architects!5e0!3m2!1sen!2sus!4v1545380250755"
              frameBorder="0"
              allowFullScreen
              style={{ width: "100%", height: "inherit", border: "0" }}
            />
          </ResponsiveMapContainer>
        </Grid>
        <br />
        <Container>
          <Typography variant="body1" component="p">
            Building number: 1011
            <br />
            Lane 16, Ghuman Nagar-A
          </Typography>
          <Link href="tel:+91-9855000865" underline="none" color="black">
            <PhoneContainer>
              <PhoneIcon />
              <span>(+91) 98550-00865</span>
            </PhoneContainer>
          </Link>
        </Container>
      </Section>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SecondMap = () => (
  <Grid xs={12} sm={6}>
    <ResponsiveMapContainer>
      <iframe
        title="Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8841.037486688449!2d76.8598621777085!3d30.67595578739874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f948427f466ff%3A0xbc0707ab571adea0!2s881%2C+Sector+21%2C+Budanpur%2C+Panchkula%2C+Haryana+134112!5e0!3m2!1sen!2sin!4v1563693471573!5m2!1sen!2sin"
        width="600"
        height="450"
        frameBorder="0"
        allowFullScreen
        style={{ width: "100%", height: "inherit", border: "0" }}
      />
    </ResponsiveMapContainer>
  </Grid>
);

export default ContactSectionTwo;
