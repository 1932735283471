import styled from "@emotion/styled";
import { blueGrey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";

import Section from "../../../components/Section";

const Container = styled.div`
  text-align: center;
`;
const ContactSectionOne = () => {
  return (
    <div>
      <Section backgroundColor={blueGrey["800"]} padding="4">
        <Container>
          <Typography variant="h4" component="h1">
            Contact Us
          </Typography>
          <br />
          <Typography variant="h5" component="h2" gutterBottom>
            Now!
          </Typography>
        </Container>
      </Section>
    </div>
  );
};

export default ContactSectionOne;
